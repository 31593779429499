import {
  GET_POSTS_FAIL,
  GET_POSTS_REQUEST,
  GET_POSTS_SUCCESS,
  RESET_PAGINATION,
} from './posts.types';

export default {
  [GET_POSTS_REQUEST](state) {
    return (state.loading = true);
  },

  [GET_POSTS_SUCCESS](state, payload) {
    console.log('p', payload.page);
    return (
      (state.posts = payload.data.data),
      (state.page_count = payload.data.meta.page_count),
      (state.page = payload.page),
      (state.error = null),
      (state.loading = false)
    );
  },

  [GET_POSTS_FAIL](state, error) {
    return (state.error = error), (state.loading = false);
  },

  [RESET_PAGINATION](state) {
    return (state.page = 1), (state.page_count = 1);
  },
};
