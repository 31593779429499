import { localStoragePrefix } from "@/config/constVars";

export default {
  errorCart: false,
  loadingCart: false,
  cart: localStorage.getItem(localStoragePrefix + "cart")
    ? JSON.parse(localStorage.getItem(localStoragePrefix + "cart"))
    : null,
  favorite: localStorage.getItem(localStoragePrefix + "favorite")
    ? JSON.parse(localStorage.getItem(localStoragePrefix + "favorite"))
    : null
};
