export default {
  // authstatus: (state) => state.authStatus,
  userGetter: (state) => {
    return {
      loadingLogin: state.loadingLogin,
      errorLogin: state.errorLogin,
      loadingSignup: state.loadingSignup,
      errorSignup: state.errorSignup,
      isAuth: !!state.token,
      currentUser: state.user,
      successStatuses: state.successStatuses,
      // authorized: !!state.token,
    };
  },
};
