export default {
  postsGetter: (state) => {
    return {
      loading: state.loading,
      error: state.error,
      page: state.page,
      page_count: state.page_count,
      posts: state.posts,
    };
  },
};
