export default {
  cartGetter: state => {
    return {
      loadingCart: state.loadingCart,
      errorCart: state.errorCart,
      cart: state.cart,
      favorite: state.favorite
    };
  }
};
