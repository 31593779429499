import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/base';
import i18n from './i18n';
import ApiService from '@/services/api.service';
import VueAwesomeSwiper from 'vue-awesome-swiper';

// import style
import 'swiper/css/swiper.css';
import VueSocialSharing from 'vue-social-sharing';

Vue.use(VueSocialSharing);

Vue.use(VueAwesomeSwiper);

Vue.prototype.window = window;

Vue.config.productionTip = false;
ApiService.init();
// Vue.prototype.$hostname = (Vue.config.productionTip) ? 'https://hostname' : 'http://localhost:3000'

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, block to current page.
    if (!store.state.userModule.token) {
      next(false);
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  data: {
    lang: 'ar',
    mobile: false,
  },
  render: (h) => h(App),
}).$mount('#app');
