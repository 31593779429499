<template>
  <div style="minHeight: 60vh;">
    <Loading :loading="loading" :overlayValue="overlayValue" />
    <!-- <div
      style="width: 100%;height:80vh"
      class="d-flex justify-center align-center"
      v-if="loading && !overlayValue"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="30"
      ></v-progress-circular>
    </div> -->
    <!-- <v-overlay v-if="overlayValue && !loading" :value="overlayValue">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay> -->
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BasePage',
  props: ['loading', 'overlayValue'],
  components: {
    Loading: () => import('../Loading.vue'),
  },
};
</script>

<style></style>
