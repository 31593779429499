import Vue from 'vue';
import Vuex from 'vuex';

import userModule from './modules/user';
import cartModule from './modules/cart';
import postsModule from './modules/posts';
import ApiService from '@/services/api.service';
import {
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
} from './constants';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    articles: require('@/data/articles.json'),
    drawer: false,
    items: [
      // {
      //   text: 'home',
      //   href: '#!',
      // },
      // {
      //   text: 'about',
      //   href: '/about',
      // },
      {
        text: 'indicators',
        to: '/indicators?type=2',
        icon: 'mdi-signal',
        color: '#1D8AC3',
      },
      {
        text: 'posts',
        to: '/posts?type=1',
        icon: 'mdi-note-multiple',
        color: '#1D8AC3',
      },
      {
        text: 'recordedCourses',
        to: '/courses?type=1',
        icon: 'mdi-album',
        color: '#1D8AC3',
      },
      {
        text: 'liveCourses',
        to: '/live-courses?type=3',
        icon: 'mdi-school',
        color: '#1D8AC3',
      },
      {
        text: 'coaching',
        to: '/coaching',
        icon: 'mdi-lightbulb-on',
        color: '#1D8AC3',
      },
      {
        text: 'aboutus',
        to: '/aboutus',
        icon: 'mdi-information',
        color: '#1D8AC3',
      },
      // {
      //   text: "favorites",
      //   to: "/favorites"
      // },
      // {
      //   text: "cart",
      //   to: "/cart"
      // }
    ],
    settings: null,
  },
  getters: {
    categories: (state) => {
      const categories = [];

      for (const article of state.articles) {
        if (
          !article.category ||
          categories.find((category) => category.text === article.category)
        )
          continue;

        const text = article.category;

        categories.push({
          text,
          href: '#!',
        });
      }

      return categories.sort().slice(0, 4);
    },
    links: (state) => {
      return state.items;
      // return state.items.concat(getters.categories);
    },
    settingsGetter: (state) => state.settings,
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: (state) => (state.drawer = !state.drawer),

    [GET_SETTINGS_REQUEST](state) {
      return (state.settingsLoading = true);
    },

    [GET_SETTINGS_SUCCESS](state, payload) {
      state.settings = payload[0];
      state.settingsLoading = false;
    },

    [GET_SETTINGS_FAIL](state, error) {
      state.errorSetings = error;
      state.settingsLoading = false;
    },
  },
  actions: {
    async getSettingsAction({ commit }) {
      try {
        commit(GET_SETTINGS_REQUEST);
        const { data } = await ApiService.get(`items/setting`);
        return commit(GET_SETTINGS_SUCCESS, data.data);
      } catch (error) {
        console.log('error', error);
        commit(GET_SETTINGS_FAIL, error);
        return;
      }
    },
  },
  modules: {
    userModule,
    cartModule,
    postsModule,
  },
});

export default store;
