import ApiService from '@/services/api.service';
import {
  ADD_TO_CART_FAIL,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  GET_CART_FAIL,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  REMOVE_FROM_CART_REQUEST,
  REMOVE_FROM_CART_SUCCESS,
  REMOVE_FROM_CART_FAIL,
} from '../../constants';

export default {
  async getCartItemsAction({ commit, getters }, type) {
    if (!getters.userGetter.currentUser) return;
    try {
      commit(GET_CART_REQUEST);
      const { data } = await ApiService.get(
        `items/${type}?fields=id,course_id.*,course_id.featured_image.data.full_url,course_id.instructor_id.*,course_id.currency_id.*&filter[student_id]=${getters.userGetter.currentUser.id}&filter[status]=published`
      );
      return commit(GET_CART_SUCCESS, { data: data.data, type });
    } catch (error) {
      return commit(GET_CART_FAIL, error);
    }
  },

  async addToCartAction({ commit, getters, dispatch }, payload) {
    try {
      commit(ADD_TO_CART_REQUEST);
      let userCartExists = await ApiService.get(
        `items/${payload.type}?fields=id,course_id.id&filter[student_id]=${getters.userGetter.currentUser.id}`
      );

      userCartExists = userCartExists.data.data;
      if (userCartExists.length > 0) {
        const productExists = userCartExists.find(
          (product) => product.course_id.id == payload.productId
          // (product) => product.product_id.id == payload.productId
        );
        console.log(productExists);
        if (productExists) {
          // do something
          console.log('product exists');
        } else {
          const item = {
            student_id: getters.userGetter.currentUser.id,
            course_id: payload.productId,
          };
          await ApiService.post(`items/${payload.type}`, item);
        }
      } else {
        const item = {
          student_id: getters.userGetter.currentUser.id,
          course_id: payload.productId,
        };
        await ApiService.post(`items/${payload.type}`, item);
      }

      dispatch('getCartItemsAction', payload.type);
      commit(ADD_TO_CART_SUCCESS);
    } catch (error) {
      console.log(error);
      // const message =
      //   error.response && error.response.data.message
      //     ? error.response.data.message
      //     : error.message;
      commit(ADD_TO_CART_FAIL, error);
    }
  },

  async removeCartItemAction({ commit, dispatch }, payload) {
    try {
      commit(REMOVE_FROM_CART_REQUEST);
      await ApiService.delete(`/items/${payload.type}/${payload.productId}`);
      commit(REMOVE_FROM_CART_SUCCESS);
      dispatch('getCartItemsAction', payload.type);
    } catch (error) {
      commit(REMOVE_FROM_CART_FAIL, error);
    }
  },
};
