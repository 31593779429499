import { localStoragePrefix } from '@/config/constVars';
import { TOKEN } from '../../constants';

export default {
  // authStatus: '',
  token: localStorage.getItem(localStoragePrefix + TOKEN) || null,
  user: localStorage.getItem(localStoragePrefix + 'userInfo')
    ? JSON.parse(localStorage.getItem(localStoragePrefix + 'userInfo'))
    : null,
  errorLogin: null,
  loadingLogin: null,
  errorSignup: null,
  loadingSignup: null,
  successStatuses: ['success', 'full_discount', 'free'],
  // user: {},
  // isAuth: localStorage[localStoragePrefix + 'TOKEN'] ? true : false,
  // isAuthenticated: localStorage[localStoragePrefix + 'TOKEN'] ? true : false,
};
