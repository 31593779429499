import Vue from 'vue';
import BaseBtn from '@/components/base/Btn';
import BaseCard from '@/components/base/Card';
import BaseSubheading from '@/components/base/Subheading';
import BasePage from '@/components/base/Page';
import Container from '@/components/base/Container';

Vue.component(BaseBtn.name, BaseBtn);
Vue.component(BaseCard.name, BaseCard);
Vue.component(BaseSubheading.name, BaseSubheading);
Vue.component(BasePage.name, BasePage);
Vue.component(Container.name, Container);
