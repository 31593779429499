<template>
  <v-container fluid style=" padding: 0px; width: 90%">
    <slot />
  </v-container>
</template>

<script>
export default {
  name: 'Container',
};
</script>

<style></style>
