import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { localStoragePrefix } from './config/constVars';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    '@/locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  console.log(messages);
  return messages;
}

// const messages = {
//   en: {
//     ...require('@/locales/en.json'),
//   },
//   ar: {
//     ...require('@/locales/ar.json'),
//   },
// };

const lang = localStorage.getItem(localStoragePrefix + 'language') || 'ar';
// const lang = localStorage.getItem(localStoragePrefix + "language") || "en";
export default new VueI18n({
  // lazy: true,
  locale: lang,
  fallbackLocale: lang,
  // locale: process.env.VUE_APP_I18N_LOCALE || "en",
  // fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  // messages,
  messages: loadLocaleMessages(),
  silentTranslationWarn: process.env.NODE_ENV === 'development',
});
