import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/test-home',
  //   name: 'TestHome',
  //   component: () => import('../views/Home'),
  // },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomePage'),
  },
  {
    path: '/aboutus',
    name: 'About',
    component: () => import('../views/AboutPage'),
  },
  {
    path: '/indicators',
    name: 'IndicatorsPage',
    component: () => import('../views/CoursesPage'),
    // component: () => import('../views/IndicatorsPage'),
  },
  // {
  //   path: '/indicator-details/:id',
  //   name: 'IndicatorDetailsPage',
  //   props: true,
  //   component: () => import('../views/IndicatorDetailsPage'),
  // },
  {
    path: '/payment/:id',
    name: 'PaymentPage',
    props: true,
    component: () => import('../views/PaymentPage'),
    meta: { requiresAuth: true },
  },
  {
    path: '/quiz/:course_id/:enrollment_id?',
    name: 'QuizPage',
    props: true,
    component: () => import('../views/QuizPage'),
    meta: { requiresAuth: true },
  },
  {
    path: '/courses',
    name: 'CoursesPage',
    component: () => import('../views/CoursesPage'),
  },
  {
    path: '/live-courses',
    name: 'LiveCoursesPage',
    component: () => import('../views/CoursesPage'),
  },
  // {
  //   path: '/course-details/:id',
  //   name: 'CourseDetailsPage',
  //   props: true,
  //   component: () => import('../views/CourseDetailsPage'),
  // },
  {
    path: '/course-content/:course_id',
    name: 'CourseContentPage',
    props: true,
    component: () => import('../views/CourseContentPage'),
    meta: { requiresAuth: true },
  },
  {
    path: '/result/:enrollment_id?',
    name: 'ResultPage',
    props: true,
    component: () => import('../views/ResultPage'),
    meta: { requiresAuth: true },
  },
  {
    path: '/cart',
    name: 'CartPage',
    component: () => import('../views/CartPage'),
    meta: { requiresAuth: true },
  },
  {
    path: '/wishlist',
    name: 'WishlistPage',
    component: () => import('../views/WishlistPage'),
    meta: { requiresAuth: true },
  },
  {
    path: '/posts',
    name: 'Posts',
    component: () => import('../views/PostsPage'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/ProfilePage'),
    meta: { requiresAuth: true },
  },
  // {
  //   path: '/success-payment',
  //   name: 'SuccessPayment',
  //   component: () => import('../views/SuccessPaymentPage'),
  //   meta: { requiresAuth: true },
  // },
  {
    path: '/post-details/:id',
    name: 'PostDetailsPage',
    props: true,
    component: () => import('../views/PostDetailsPage'),
  },
  {
    path: '/details/:id',
    name: 'CourseDetailsDisplay',
    props: true,
    component: () => import('../views/CourseDetailsDisplayPage'),
  },
  {
    path: '/my-tests',
    name: 'MyTests',
    component: () => import('../views/UserProductsPage'),
    meta: { requiresAuth: true },
  },
  {
    path: '/my-courses',
    name: 'MyCourses',
    component: () => import('../views/UserProductsPage'),
    meta: { requiresAuth: true },
  },
  {
    path: '/coaching',
    name: 'Coaching',
    component: () => import('../views/CoachingPage'),
  },

  {
    path: '/payment-result',
    name: 'PaymentResult',
    // props: true,
    component: () => import('../views/PaymentResult'),
    meta: { requiresAuth: true },
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/HomePage'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
