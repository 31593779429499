import ApiService from '@/services/api.service';
import {
  GET_POSTS_FAIL,
  GET_POSTS_REQUEST,
  GET_POSTS_SUCCESS,
  RESET_PAGINATION,
} from './posts.types';

export default {
  async getPostsAction({ commit, state }, payload) {
    try {
      console.log('type', payload.type);
      commit(GET_POSTS_REQUEST);

      let url = `items/posts_tags?fields=*,post_id.*,post_id.featured_image.id,post_id.featured_image.data.full_url&filter[post_id.status]=published&filter[tag_id]=2&meta=*&limit=${state.limit}&page=${payload.page}&filter[tag_id]=${payload.type}`;

      // payload.type && (url += `&filter[tag_id]=${payload.type}`);

      const { data } = await ApiService.get(url);

      return commit(GET_POSTS_SUCCESS, { data, page: payload.page });
    } catch (error) {
      console.log('error', error);
      commit(GET_POSTS_FAIL, error);
      return;
    }
  },

  resetPaginationAction({ commit }) {
    return commit(RESET_PAGINATION);
  },
};
