import ApiService from '@/services/api.service';
import JwtService from '@/services/jwt.service';
import {
  PURGE_AUTH,
  SET_AUTH,
  // SET_ERROR,
  UPDATE_USER,
  VERIFY_AUTH,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNUP_FAIL,
} from '../../constants';

export default {
  async login({ commit }, credentials) {
    try {
      commit(USER_SIGNIN_REQUEST);
      const { data } = await ApiService.post('auth/authenticate', credentials);
      // commit(USER_SIGNIN_SUCCESS);
      console.log({ login: data.data });
      return commit(USER_SIGNIN_SUCCESS, data.data);
    } catch (error) {
      console.log('error', error);
      commit(USER_SIGNIN_FAIL, error);
      // commit(SET_ERROR, error);
      return;
    }
  },

  logoutAction({ commit }) {
    commit(PURGE_AUTH);
  },

  async signup({ commit }, credentials) {
    try {
      // register user
      commit(USER_SIGNUP_REQUEST);
      const { data } = await ApiService.registerUser('users', credentials);
      // const { data } = await ApiService.post('users', credentials);
      commit(USER_SIGNUP_SUCCESS);
      console.log('signup res', data.data);
    } catch (error) {
      console.log(error);
      commit(USER_SIGNUP_FAIL, error);
      // return commit(SET_ERROR, error);
    }
  },

  [VERIFY_AUTH]({ commit }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get('users/me')
        .then(() => {})
        .catch(({ data }) => {
          console.log(data);
        });
    } else {
      commit(PURGE_AUTH);
    }
  },

  async [UPDATE_USER]({ commit }, payload) {
    try {
      const { email, username, password, image } = payload;
      const user = { email, username, image };
      if (password) {
        user.password = password;
      }

      const { data } = await ApiService.put('user', user);
      commit(SET_AUTH, data);
    } catch (error) {
      console.log(error);
    }
  },
};
