import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from '../i18n';

Vue.use(Vuetify);

// this.$vuetify.rtl = false;
// this.$i18n.locale = 'en';
export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    options: {
      customProperties: true,
    },

    themes: {
      light: {
        primary: '#1D8AC3',
        secondary: '#F46727',
        accent: '#FB9D2F',
        greyBg: '#EAEFF0',
        twitter: '#1da1f2',
        whatsapp: '#25d366',
        facebook: '#1877f2',
      },
    },
  },
});
