import actions from './posts.actions';
import getters from './posts.getters';
import mutations from './posts.mutations';
import state from './posts.state';

export default {
  actions,
  getters,
  mutations,
  state,
};
