import { localStoragePrefix, TOKEN } from "@/config/constVars";

export const getToken = () => {
  return window.localStorage.getItem(localStoragePrefix + TOKEN);
};

export const saveToken = token => {
  window.localStorage.setItem(localStoragePrefix + TOKEN, token);
};

export const destroyToken = () => {
  localStorage.clear();
};

export default { getToken, saveToken, destroyToken };
