import {
  ADD_TO_CART_FAIL,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  GET_CART_FAIL,
  REMOVE_FROM_CART_REQUEST,
  REMOVE_FROM_CART_SUCCESS,
  REMOVE_FROM_CART_FAIL
} from "../../constants";

export default {
  // get cart items
  [GET_CART_REQUEST](state) {
    state.loadingCart = true;
  },

  [GET_CART_SUCCESS](state, payload) {
    // state.cart = cart;
    state[payload.type] = payload.data;
    state.loadingCart = false;
  },

  [GET_CART_FAIL](state, error) {
    state.errorCart = error;
    state.loadingCart = false;
  },

  // add to cart
  [ADD_TO_CART_REQUEST](state) {
    state.loadingCart = true;
  },

  [ADD_TO_CART_SUCCESS](state) {
    state.loadingCart = false;
  },

  [ADD_TO_CART_FAIL](state, error) {
    state.errorCart = error;
    state.loadingCart = false;
  },

  // remove from cart
  [REMOVE_FROM_CART_REQUEST](state) {
    state.loadingCart = true;
  },

  [REMOVE_FROM_CART_SUCCESS](state) {
    state.loadingCart = false;
  },

  [REMOVE_FROM_CART_FAIL](state, error) {
    state.errorCart = error;
    state.loadingCart = false;
  }
};
