<template>
  <v-app
    :dir="pointto"
    :direction="pointto"
    :style="'direction: ' + pointto"
    class="app"
  >
    <!-- style="background: #EAEFF0 !important;paddingTop:20px" -->
    <core-app-bar />

    <core-drawer />

    <core-view />

    <core-footer />

    <!-- <core-cta /> -->
    <!-- <v-container style="position:relative;padding:0;" fluid>
      <Navbar />
    </v-container>

    <v-main style="marginTop:64px">
      <router-view />
    </v-main>

    <v-footer app class="pa-0">
      <div
        style="width: 100vw; height: 200px"
        class="grey lighten-3 d-flex align-center justify-center"
      >
        <span class="body-1">footer</span>
      </div>
    </v-footer> -->
  </v-app>
</template>

<script>
// import Navbar from './components/Navbar';
import { localStoragePrefix } from '@/config/constVars';
export default {
  name: 'App',

  components: {
    // Navbar,
    // CoreCta: () => import("@/components/core/Cta"),
    CoreDrawer: () => import('@/components/core/Drawer'),
    CoreFooter: () => import('@/components/core/Footer'),
    CoreAppBar: () => import('@/components/core/AppBar'),
    CoreView: () => import('@/components/core/View'),
  },

  data: () => ({
    pointto: 'rtl',
    // pointto: 'ltr',
    // fontFamily:
    //   "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
  }),
  methods: {
    // top() {
    //   window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: 'smooth',
    //   });
    // },
  },
  beforeCreate() {
    this.$vuetify.rtl = true;
    if (localStorage[localStoragePrefix + 'language'] == 'en') {
      // this.fontFamily = "'Tajawal', sans-serif !important";
      this.pointto = 'ltr';
      this.$vuetify.rtl = false;
    } else {
      // this.fontFamily =
      //   "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;";
      this.pointto = 'rtl';
      this.$vuetify.rtl = true;
    }

    // this.$vuetify.rtl = true;
    // if (localStorage[localStoragePrefix + 'language'] == 'ar') {
    //   // this.fontFamily = "'Tajawal', sans-serif !important";
    //   this.pointto = 'rtl';
    //   this.$vuetify.rtl = true;
    // } else {
    //   // this.fontFamily =
    //   //   "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;";
    //   this.pointto = 'ltr';
    //   this.$vuetify.rtl = false;
    // }
  },
  created() {
    // console.log('local settings:', localStorage.SETTINGS);
    // if (!localStorage.SETTINGS) {
    //   this.fetchSettings();
    // }
  },
  mounted() {
    this.$nextTick(function() {
      if (screen.width <= 815) {
        this.$root.mobile = true;
      } else {
        this.$root.mobile = false;
      }
      // this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');
      if (localStorage[localStoragePrefix + 'language']) {
        this.$root.lang = localStorage[localStoragePrefix + 'language'];
      }
      if (localStorage[localStoragePrefix + 'language'] == 'en') {
        // this.fontFamily = "'Tajawal', sans-serif !important";
        this.pointto = 'ltr';
        this.$vuetify.rtl = false;
      } else {
        this.pointto = 'rtl';
        this.$vuetify.rtl = true;
      }
    });
  },
};

// <style scoped>
// .parent-element >>> .vuetify-class {
//   // values
// }
// </style>

// <style lang="scss" scoped>
//   .vuetify-class {
//     ::v-deep other-class {
//       // your custom css properties
//     }
//   }
// </style>
</script>

<style lang="scss">
// $body-font-family: 'Tajawal', sans-serif !important;

// @import '~vuetify/src/styles/styles.sass';

// $font-family: 'Tajawal', sans-serif !important;
// .v-application {
//   [class*='text-'],
//   [class*='body-'],
//   [class*='overline'] {
//     font-family: $font-family;
//   }
//   font-family: $font-family;
// }

.app {
  background: var(--v-greyBg-base) !important;
  padding-top: 20px;
}

.fs-18 {
  font-size: 18px;
}
</style>
